@charset "UTF-8";
@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Alegreya_Sans_400.woff2") format("woff2"), url("../fonts/Alegreya_Sans_400.woff") format("woff"), url("../fonts/Alegreya_Sans_400.eot?#iefix") format("embedded-opentype"), url("../fonts/Alegreya_Sans_400.svg#AlegreyaSans") format("svg"), url("../fonts/Alegreya_Sans_400.ttf") format("truetype"); }

@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Alegreya_Sans_500.woff2") format("woff2"), url("../fonts/Alegreya_Sans_500.woff") format("woff"), url("../fonts/Alegreya_Sans_500.eot?#iefix") format("embedded-opentype"), url("../fonts/Alegreya_Sans_500.svg#AlegreyaSans") format("svg"), url("../fonts/Alegreya_Sans_500.ttf") format("truetype"); }

@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Alegreya_Sans_700.woff2") format("woff2"), url("../fonts/Alegreya_Sans_700.woff") format("woff"), url("../fonts/Alegreya_Sans_700.eot?#iefix") format("embedded-opentype"), url("../fonts/Alegreya_Sans_700.svg#AlegreyaSans") format("svg"), url("../fonts/Alegreya_Sans_700.ttf") format("truetype"); }

/*********************************************************************
 * Susy Grid
 *
 */
/* GLOBAL CONTAINER SETTINGS */
.block--announcement,
.header,
.content,
.footer {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }
  .block--announcement:after,
  .header:after,
  .content:after,
  .footer:after {
    content: " ";
    display: block;
    clear: both; }

html {
  margin: 0;
  padding: 0; }

/* 67px @ 1920px (full width) */
body {
  padding-left: 3.48958%;
  /* 67px @ 1920px (full width) */
  padding-right: 3.48958%; }

/* COLORS */
/* /COLORS */
/* TYPOGRAPHY */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Alegreya Sans", "Calluna Sans", "FF Scala Sans", "Myriad Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1; }

b,
strong {
  font-weight: 700; }

body {
  font-family: "Alegreya Sans", "Calluna Sans", "FF Scala Sans", "Myriad Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  line-height: 1.5; }

/* /TYPOGRAPHY */
/* MAIN */
html {
  height: 101%;
  overflow-y: scroll; }

body {
  margin-top: 0; }

a {
  outline: none; }

a:link,
a:visited {
  border-bottom: 1px solid transparent;
  color: #fff;
  outline: 0;
  text-decoration: none;
  transition: border-bottom-color .4s, color .4s; }

a:hover,
a:active,
a:focus {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  outline: 0;
  text-decoration: none; }

a:focus,
a:active {
  border-bottom: 0; }

a.btn:link,
a.btn:visited {
  border-bottom: 0;
  transition: background-color .4s; }

a.btn:hover,
a.btn:active,
a.btn:focus {
  border-bottom: 0; }

img {
  border: 0;
  outline: 0; }

p {
  margin: 1em 0; }

/* /MAIN */
/* HEADER */
.header {
  width: 100%; }
  .header .nav--logo {
    box-sizing: border-box;
    height: 180px;
    margin-bottom: 6px;
    text-align: center; }
    .header .nav--logo:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle; }
    .header .nav--logo img {
      display: inline-block;
      height: 120px;
      vertical-align: middle; }
  .header .nav--menu {
    margin-bottom: 6px;
    text-align: center; }
  .header .nav--info .nav--info--left,
  .header .nav--info .nav--info--right {
    margin-bottom: 6px; }
  .header .nav--info .nav--info--left .text {
    font-size: 16px; }
  .header .nav--info .nav--info--right .table .th {
    font-size: 20px;
    text-align: left; }
  .header .nav--info .nav--info--right .table .td:first-child {
    white-space: nowrap;
    width: 1%; }
  .header .nav--info .nav--info--right .table .tbody {
    font-size: 16px; }

@media (min-width: 360px) {
  .header .nav--info .nav--info--left .text {
    font-size: 20px; }
  .header .nav--info .nav--info--right .table .th {
    font-size: 22px; }
  .header .nav--info .nav--info--right .table .tbody {
    font-size: 18px; } }

@media (min-width: 768px) {
  .header .nav--logo {
    height: 210px; }
    .header .nav--logo img {
      height: 150px; }
  .header .nav--info {
    background-color: #646660; }
    .header .nav--info:after {
      clear: both;
      content: " ";
      display: block;
      line-height: 0; }
    .header .nav--info .nav--info--left {
      float: left;
      width: 55%; }
    .header .nav--info .nav--info--right {
      float: left;
      width: 45%; }
      .header .nav--info .nav--info--right .table .th {
        position: relative;
        right: -5px;
        text-align: right; }
      .header .nav--info .nav--info--right .table .td:first-child {
        white-space: nowrap;
        width: auto; }
      .header .nav--info .nav--info--right .table .td:last-child {
        text-align: right;
        white-space: nowrap;
        width: 1%; } }

@media (min-width: 1024px) {
  .header {
    position: relative; }
    .header .nav--logo {
      border-right: 6px solid #fff;
      bottom: 0;
      float: left;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 33%; }
      .header .nav--logo:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle; }
      .header .nav--logo img {
        height: auto;
        max-width: 270px;
        width: auto; }
    .header .nav--menu {
      float: right;
      text-align: right;
      width: 67%; }
    .header .nav--info {
      float: right;
      width: 67%; } }

.header {
  margin-top: 6px; }
  .header .nav--logo {
    background-color: #8b8d85; }
    .header .nav--logo .navbar-brand,
    .header .nav--logo .navbar-brand:hover {
      border: 0;
      transition: none; }
  .header .nav--menu {
    background-color: #09a4d0;
    line-height: 1;
    padding: 1px 0; }
    .header .nav--menu ul,
    .header .nav--menu li {
      list-style: none;
      margin: 0;
      padding: 0; }
    .header .nav--menu ul {
      margin: .75em 1em; }
    .header .nav--menu li {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      display: inline-block;
      font-size: 20px;
      padding: 0 16px; }
      .header .nav--menu li:last-child {
        border-right: 0; }
      .header .nav--menu li .nav--link.current {
        color: rgba(255, 255, 255, 0.5);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .header .nav--info {
    color: #fff;
    margin-bottom: 6px;
    text-decoration: none; }
    .header .nav--info .nav--info--left {
      background-color: #646660;
      box-sizing: border-box;
      padding: 8px 24px; }
      .header .nav--info .nav--info--left .text {
        font-weight: 400;
        line-height: 26px;
        margin-bottom: .6em;
        margin-top: .6em;
        padding-left: 10px; }
        .header .nav--info .nav--info--left .text a:link,
        .header .nav--info .nav--info--left .text a:visited {
          color: #fff;
          text-decoration: none; }
        .header .nav--info .nav--info--left .text a:hover,
        .header .nav--info .nav--info--left .text a:active,
        .header .nav--info .nav--info--left .text a:focus {
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          color: rgba(255, 255, 255, 0.5); }
        .header .nav--info .nav--info--left .text.btn {
          background-color: #e0831c;
          border-radius: 6px;
          color: #fff;
          display: block;
          padding: 0 20px 7px 10px;
          text-decoration: none; }
          .header .nav--info .nav--info--left .text.btn:hover {
            background-color: #e0611c;
            border: 0; }
        .header .nav--info .nav--info--left .text svg {
          bottom: -8px;
          margin-right: 8px;
          position: relative; }
    .header .nav--info .nav--info--right {
      background-color: #646660;
      box-sizing: border-box;
      padding: 8px 32px; }
      .header .nav--info .nav--info--right .table {
        border-collapse: collapse;
        border-spacing: 0;
        border-width: 0;
        margin-bottom: 12px;
        margin-top: 10px; }
      .header .nav--info .nav--info--right .th,
      .header .nav--info .nav--info--right .td {
        margin: 0;
        padding: 0;
        width: auto; }
      .header .nav--info .nav--info--right .th {
        color: #c9ccc0;
        font-weight: 700;
        letter-spacing: .15em;
        position: relative;
        text-align: left;
        text-transform: uppercase; }
      .header .nav--info .nav--info--right .trh,
      .header .nav--info .nav--info--right .trb:nth-child(2),
      .header .nav--info .nav--info--right .trb:nth-child(4) {
        border-bottom: .5em solid transparent; }
      .header .nav--info .nav--info--right .td:first-child {
        padding-right: 1em;
        text-align: right; }
      .header .nav--info .nav--info--right .tbody {
        font-weight: 700; }

/* /HEADER */
/* FOOTER */
.footer {
  background-color: #09a4d0;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 6px;
  padding-bottom: .6em;
  padding-top: .6em;
  position: relative;
  width: 100%; }
  .footer .left {
    flex-grow: 1;
    padding-left: 32px;
    padding-right: 32px;
    text-align: left; }
  .footer .right {
    flex-grow: 1;
    margin: 0;
    padding-right: 32px;
    text-align: right; }

/* /FOOTER */
/* ANNOUNCEMENT */
.block--announcement {
  background-color: #e0611c;
  border-top: 0;
  margin-bottom: 6px;
  margin-top: 6px; }
  .block--announcement .block--announcement--inner {
    color: #fff;
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding: 12px 32px;
    text-align: center; }
    .block--announcement .block--announcement--inner p {
      margin-bottom: .5em;
      margin-top: .5em; }
    .block--announcement .block--announcement--inner h3 {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: .015em;
      margin-bottom: .3em;
      margin-top: .3em;
      text-transform: uppercase; }
      .block--announcement .block--announcement--inner h3 strong {
        font-weight: 700; }

/* /ANNOUNCEMENT */
/* COOKIE CONSENT */
.pwcmb {
  box-sizing: border-box; }

.pwcmb * {
  box-sizing: border-box; }

.pwcmb {
  position: fixed;
  z-index: 601;
  width: 100%;
  left: 0;
  transition: top 0.25s; }

.js-show.pwcmb--top_overlay,
.js-show.pwcmb--top_push {
  top: 0px; }

.pwcmb--bottom_overlay {
  bottom: -400px; }

.js-show.pwcmb--bottom_overlay {
  bottom: 0px; }

.pwcmb-widget {
  border-top: 6px solid white;
  position: absolute;
  width: 100%;
  transition: 0.25s;
  background: #e0611c;
  font-size: 18px;
  padding: 0 4.75%; }

.pwcmb--bottom_overlay .pwcmb-widget {
  bottom: -100vh; }

.pwcmb--bottom_overlay .js-show.pwcmb-widget {
  bottom: 0; }

.pwcmb--top_overlay .pwcmb-widget {
  top: -100vh; }

.pwcmb--top_overlay .js-show.pwcmb-widget {
  top: 0; }

.pwcmb-widget__close {
  visibility: hidden;
  cursor: pointer;
  display: none; }

.pwcmb-widget__close::before {
  content: '×';
  visibility: visible;
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 30px;
  overflow: hidden; }

.pwcmb-widget__copy {
  padding-right: 20px; }

.pwcmb-widget__title {
  font-weight: bold; }

.pwcmb-widget__row-label {
  font-weight: bold; }

.pwcmb-widget__row-text {
  display: inline; }

.pwcmb-widget__row-text:before {
  content: ' - '; }

.pwcmb-widget__actions {
  white-space: nowrap; }

.pwcmb-widget button {
  white-space: nowrap;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 5px 8px;
  min-width: 100px;
  font-size: 18px;
  font-family: "Alegreya Sans", "Calluna Sans", "FF Scala Sans", "Myriad Pro", Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1; }

.js-pwcmb-notice-toggle {
  cursor: pointer; }

.pwcmb-option-wrapper {
  position: relative; }

@media screen and (max-width: 767px) {
  .pwcmb-widget__inner {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto; }
  .pwcmb-widget__row-label,
  .pwcmb-widget__text {
    font-size: 18px; }
  .pwcmb-widget__actions {
    padding-top: 1px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (min-width: 768px) {
  .pwcmb-widget__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .pwcmb-widget__actions {
    margin-left: auto; } }

.pwcmb-widget--message {
  display: none; }

/* /COOKIE CONSENT */
/* HOME */
.tpl-home .block--top .block--img-top {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%; }
  .tpl-home .block--top .block--img-top .block--img-top--inner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.tpl-home .block--bottom .block--img-bottom {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%;
  margin-top: 6px; }
  .tpl-home .block--bottom .block--img-bottom .block--img-bottom--inner {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.tpl-home .block--bottom .block--map {
  margin-bottom: 6px; }
  .tpl-home .block--bottom .block--map iframe {
    display: block; }

@media (min-width: 768px) {
  .tpl-home .block--top {
    display: table;
    width: 100%;
    position: relative; }
    .tpl-home .block--top .block--img-top {
      border-right: 6px solid #fff;
      box-sizing: border-box;
      display: table-cell;
      height: auto;
      margin-bottom: 0;
      padding-top: 0;
      overflow: hidden;
      width: 50%; }
      .tpl-home .block--top .block--img-top .block--img-top--inner {
        height: 100%;
        width: 100%; }
    .tpl-home .block--top .block--welcome {
      display: table-cell;
      width: 50%; }
  .tpl-home .block--bottom {
    display: table;
    margin-top: 6px;
    position: relative;
    width: 100%; }
    .tpl-home .block--bottom .block--img-bottom {
      border-right: 6px solid #fff;
      box-sizing: border-box;
      display: table-cell;
      height: auto;
      margin-bottom: 0;
      padding-top: 0;
      overflow: hidden;
      width: 50%; }
      .tpl-home .block--bottom .block--img-bottom .block--img-bottom--inner {
        height: 100%;
        width: 100%; }
    .tpl-home .block--bottom .block--map {
      display: table-cell;
      margin-bottom: 0;
      margin-top: 6px;
      width: 50%; } }

@media (min-width: 1024px) {
  .tpl-home .block--top .block--img-top {
    width: 33%; }
  .tpl-home .block--top .block--welcome {
    width: 67%; }
  .tpl-home .block--bottom .block--img-bottom {
    width: 33%; }
  .tpl-home .block--bottom .block--map {
    width: 67%; } }

.tpl-home .block--top .block--img-top {
  background-color: #09a4d0; }

.tpl-home .block--top .block--welcome {
  background-color: #8b8d85;
  font-size: 18px; }
  .tpl-home .block--top .block--welcome .block--welcome--inner {
    color: #fff;
    padding: 12px 32px; }

.tpl-home .block--bottom .block--img-bottom {
  background-color: #e0831c; }

.tpl-home .block--bottom .block--map {
  background-color: #8b8d85; }
  .tpl-home .block--bottom .block--map iframe {
    background-color: #eaeaea;
    height: 420px;
    pointer-events: none;
    width: 100%; }

.tpl-home .block--services {
  background-color: #646660;
  margin-top: 6px; }
  .tpl-home .block--services .block--services--inner {
    color: #fff;
    font-size: 18px;
    padding: 12px 32px;
    text-align: center; }
    .tpl-home .block--services .block--services--inner p {
      margin-top: .75em; }
    .tpl-home .block--services .block--services--inner h3 {
      color: #e0831c;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: .75em; }
      .tpl-home .block--services .block--services--inner h3 strong {
        font-weight: 700; }

/* /HOME */
/* KONTAKT */
.tpl-kontakt .content .block--left .block--img-top {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%; }
  .tpl-kontakt .content .block--left .block--img-top .block--img-top--inner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.tpl-kontakt .content .block--left .block--img-bottom {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%;
  margin-top: 6px; }
  .tpl-kontakt .content .block--left .block--img-bottom .block--img-bottom--inner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.tpl-kontakt .content .block--right {
  margin-bottom: 6px; }

@media (min-width: 1024px) {
  .tpl-kontakt .content {
    display: table;
    background-color: rgba(255, 0, 0, 0.25); }
    .tpl-kontakt .content .block--left {
      display: table-cell;
      width: 33%;
      margin-right: 6px solid #fff; }
    .tpl-kontakt .content .block--right {
      display: table-cell;
      width: 67%;
      margin-bottom: 0; } }

.tpl-kontakt .block--left {
  background-color: #8b8d85;
  border-right: 6px solid #fff;
  box-sizing: border-box; }
  .tpl-kontakt .block--left .block--img-top {
    background-color: #09a4d0; }
  .tpl-kontakt .block--left .block--img-bottom {
    background-color: #e0831c; }

.tpl-kontakt .block--right .block--kontakt {
  background-color: #8b8d85;
  font-size: 18px; }
  .tpl-kontakt .block--right .block--kontakt .block--kontakt--inner {
    color: #fff;
    padding: 12px 32px; }
    .tpl-kontakt .block--right .block--kontakt .block--kontakt--inner h3 {
      color: #c9ccc0;
      font-weight: 700;
      letter-spacing: .15em;
      text-transform: uppercase; }

/* /KONTAKT */
