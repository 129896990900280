/* TYPOGRAPHY */

// @font-face {
//  font-family: 'Inconsolata';
//  src: url('../fonts/inconsolata.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/inconsolata.woff') format('woff'),
//       url('../fonts/inconsolata.ttf')  format('truetype'),
//       url('../fonts/inconsolata.svg#inconsolata') format('svg');
// }

$font-printwerk: "Alegreya Sans", "Calluna Sans", "FF Scala Sans", "Myriad Pro", Helvetica, Arial, sans-serif;

@mixin font-printwerk {
  font-family: $font-printwerk;
  font-weight: 400;
  line-height: 1;
}

$default-text-shadow-color: rgba(0, 0, 0, .6);
$default-text-shadow-blur: 3px;
$default-text-shadow-v-offset: 2px;


h1,
h2,
h3,
h4,
h5,
h6 { @include font-printwerk; }

b,
strong {
  font-weight: 700;
}


// h1 {
//   font-size: 28px;
//   text-transform: uppercase;
//   margin-top:0;
//   padding-top:0;
// }
// h2 {
//   font-size: 24px;
//   // margin-bottom: 21px;
// }
// h3 {
//   font-size: 20px;
//   // margin-bottom: 21px;
// }
// h4 {
//   font-size: 18px;
// }
// h5 {
//   text-transform: uppercase;
//   font-size: 16px;
// }
// h6 {
//   font-size: 16px;
// }

body {
  @include font-printwerk;
  font-size: 16px;
  line-height: 1.5;
}

/* /TYPOGRAPHY */
