/* HOME */

// === Home Mixins ===========================================================

@mixin bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin bg-image-parent-mobile {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

@mixin bg-image-parent-tablet {
  border-right: 6px solid $white;
  box-sizing: border-box;
  display: table-cell;
  height: auto;
  margin-bottom: 0;
  padding-top: 0;
  overflow: hidden;
}

// === Home Responsive =======================================================

// --- MOBILE ----------------------------------------------------------------

.tpl-home {
  .block--top {
    .block--img-top {
      @include bg-image-parent-mobile;

      .block--img-top--inner {
        @include bg-image;
      }
    }
  }

  .block--bottom {
    .block--img-bottom {
      @include bg-image-parent-mobile;
      margin-top: 6px;

      .block--img-bottom--inner {
        @include bg-image;
      }
    }

    .block--map {
      margin-bottom: 6px;

      iframe {
        display: block;
      }
    }
  }
}

// --- TABLET ----------------------------------------------------------------

@media (min-width: 768px) {

  .tpl-home {
    .block--top {
      display: table;
      width: 100%;
      position: relative;

      .block--img-top {
        @include bg-image-parent-tablet;
        width: 50%;
        .block--img-top--inner {
          height: 100%;
          width: 100%;
        }
      }

      .block--welcome {
        display: table-cell;
        width: 50%;
      }
    }

    .block--bottom {
      display: table;
      margin-top: 6px;
      position: relative;
      width: 100%;

      .block--img-bottom {
        @include bg-image-parent-tablet;
        width: 50%;

        .block--img-bottom--inner {
          height: 100%;
          width: 100%;
        }
      }

      .block--map {
        display: table-cell;
        margin-bottom: 0;
        margin-top: 6px;
        width: 50%;
      }
    }
  }

}


// --- DESKTOP ---------------------------------------------------------------

@media (min-width: 1024px) {

  .tpl-home {
    .block--top {
      .block--img-top {
        width: 33%;
      }

      .block--welcome {
        width: 67%;
      }
    }

    .block--bottom {
      .block--img-bottom {
        width: 33%;
      }

      .block--map {
        width: 67%;
      }
    }
  }

}


// === Home Basics ===========================================================

.tpl-home {


  // *** BLOCK TOP ***********************************************************

  .block--top {
    .block--img-top {
      background-color: $blue;

      // .block--img-top--inner {
      //   border-bottom: 6px solid $white;
      //   box-sizing: border-box;
      // }
    }

    .block--welcome {
      background-color: $gray;
      font-size: 18px;

      .block--welcome--inner {
        color: $white;
        padding: 12px 32px;
      }
    }
  }


  // *** BLOCK BOTTOM ********************************************************

  .block--bottom {
    .block--img-bottom {
      background-color: $orange;
    }

    .block--map {
      background-color: $gray;

      iframe {
        background-color: $bg-google-map-iframe;
        height: 420px;
        pointer-events: none;
        width: 100%;
      }
    }
  }


  // *** BLOCK SERVICES ******************************************************

  .block--services {
    background-color: $dgray;
    margin-top: 6px;

    .block--services--inner {
      color: $white;
      font-size: 18px;
      padding: 12px 32px;
      text-align: center;

      p {
        margin-top: .75em;
      }

      h3 {
        color: $orange;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: .75em;

        strong {
          font-weight: 700;
        }
      }

    }
  }

}

/* /HOME */
