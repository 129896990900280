/* ANNOUNCEMENT */


// === Announcement Basics =========================================================

.block--announcement {
  background-color: $dorange;
  border-top: 0;
  margin-bottom: 6px;
  margin-top: 6px;

  .block--announcement--inner {
    color: $white;
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
    padding: 12px 32px;
    text-align: center;

    p {
      margin-bottom: .5em;
      margin-top: .5em;
    }

    h3 {
      color: $white;
      font-size: 28px;
      font-weight: 700;
      letter-spacing: .015em;
      margin-bottom: .3em;
      margin-top: .3em;
      text-transform: uppercase;

      strong {
        font-weight: 700;
      }
    }

  }
}

/* /ANNOUNCEMENT */
