/* HEADER */


// === Header Responsive =====================================================

// --- MOBILE ----------------------------------------------------------------

.header {
  width: 100%;

  .nav--logo {
    box-sizing: border-box;
    height: 180px;
    margin-bottom: 6px;
    text-align: center;

    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;

    }

    img {
      display: inline-block;
      height: 120px;
      vertical-align: middle;
    }
  }

  .nav--menu {
    margin-bottom: 6px;
    text-align: center; // Center Aligned Menu
  }

  .nav--info {
    .nav--info--left,
    .nav--info--right {
      margin-bottom: 6px;
    }

    .nav--info--left { // Kontaktdaten & DL Button
      .text {
        font-size: 16px;
      }
    }

    // Left Aligned Table
    .nav--info--right { // Öffnungszeiten
      .table {
        .th {
          font-size: 20px;
          text-align: left;
        }

        .td:first-child {
          white-space: nowrap;
          width: 1%;
        }

        .tbody {
          font-size: 16px;
        }
      }
    }
  }
}

// Text Size Adjustments larger Mobile Screens

@media (min-width: 360px) {

  .header {
    .nav--info {
      .nav--info--left { // Kontaktdaten & DL Button
        .text {
          font-size: 20px;
        }
      }

      .nav--info--right { // Öffnungszeiten
        .table {
          .th {
            font-size: 22px;
          }

          .tbody {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// --- TABLET ----------------------------------------------------------------

@media (min-width: 768px) {

  .header {

    .nav--logo {
      height: 210px;

      img {
        height: 150px;
      }
    }

    .nav--info {
      @include cf();
      background-color: $dgray;

      .nav--info--left {
        float: left;
        width: 55%;
      }

      // Right Aligned Table
      .nav--info--right {
        float: left;
        width: 45%;

        .table {
          .th {
            position: relative;
            right: -5px;
            text-align: right;

          }

          .td:first-child {
            white-space: nowrap;
            width: auto;
          }

          .td:last-child {
            text-align: right;
            white-space: nowrap;
            width: 1%;
          }
        }
      }
    }
  }
}

// --- DESKTOP ---------------------------------------------------------------

@media (min-width: 1024px) {

  .header {
    position: relative;

    .nav--logo {
      border-right: 6px solid $white;
      bottom: 0;
      float: left;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 33%;

      &:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      img {
        height: auto;
        max-width: 270px;
        width: auto;
      }
    }

    .nav--menu {
      float: right;
      text-align: right; // Center Aligned Menu
      width: 67%;
    }

    .nav--info {
      float: right;
      width: 67%;
    }
  }
}


// === Header Basics =========================================================

.header {

  margin-top: 6px;

  // *** THE LOGO ************************************************************

  .nav--logo {
    background-color: $gray;

    .navbar-brand,
    .navbar-brand:hover {
      border: 0;
      transition: none;
    }

  }


  // *** THE PAGE MENU *******************************************************

  .nav--menu {
    background-color: $blue;
    line-height: 1;
    padding: 1px 0;

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      margin: .75em 1em;
    }

    li {
      border-right: 1px solid $white-50;
      display: inline-block;
      font-size: 20px;
      padding: 0 16px;

      &:last-child {
        border-right: 0;
      }

      .nav--link {
        // color: $white;
        // letter-spacing: .05em;
        // text-decoration: none;

        &.current {
          color: $white-50;
          border-bottom: 1px solid $white-50;
        }
      }
    }
  }


  // *** CONTACT DATA / OPENING HOURS ****************************************

  .nav--info {
    color: $white;
    margin-bottom: 6px;
    text-decoration: none;


    // --- LEFT: CONTACT DATA ------------------------------------------------

    .nav--info--left {
      background-color: $dgray;
      box-sizing: border-box;
      padding: 8px 24px;

      .text {

        font-weight: 400;
        line-height: 26px;
        margin-bottom: .6em;
        margin-top: .6em;
        padding-left: 10px;

        a:link,
        a:visited {
          color: $white;
          text-decoration: none;
        }

        a:hover,
        a:active,
        a:focus {
          border-bottom: 1px solid $white-50;
          color: $white-50;
        }

        &.btn {
          background-color: $orange;
          border-radius: 6px;
          color: $white;
          display: block;
          padding: 0 20px 7px 10px;
          text-decoration: none;

          &:hover {
            background-color: $dorange;
            border: 0;
          }
        }

        svg {
          bottom: -8px;
          margin-right: 8px;
          position: relative;
        }
      }
    }


    // --- RIGHT: OPENING HOURS ----------------------------------------------

    .nav--info--right {
      background-color: $dgray;
      box-sizing: border-box;
      padding: 8px 32px;

      .table {
        border-collapse: collapse;
        border-spacing: 0;
        border-width: 0;
        margin-bottom: 12px;
        margin-top: 10px;
      }

      .th,
      .td {
        margin: 0;
        padding: 0;
        width: auto;
      }

      .th {
        color: $lgray;
        font-weight: 700;
        letter-spacing: .15em;
        position: relative;
        text-align: left;
        text-transform: uppercase;
      }

      .trh,
      .trb:nth-child(2),
      .trb:nth-child(4) {
        border-bottom: .5em solid transparent;
      }

      .td:first-child {
        padding-right: 1em;
        text-align: right;
      }

      .tbody {
        font-weight: 700;
      }
    }
  }
}

/* /HEADER */
