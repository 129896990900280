@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 400;
  src:
    url('../fonts/Alegreya_Sans_400.woff2') format('woff2'),
    url('../fonts/Alegreya_Sans_400.woff') format('woff'),
    url('../fonts/Alegreya_Sans_400.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Alegreya_Sans_400.svg#AlegreyaSans') format('svg'),
    url('../fonts/Alegreya_Sans_400.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 500;
  src:
    url('../fonts/Alegreya_Sans_500.woff2') format('woff2'),
    url('../fonts/Alegreya_Sans_500.woff') format('woff'),
    url('../fonts/Alegreya_Sans_500.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Alegreya_Sans_500.svg#AlegreyaSans') format('svg'),
    url('../fonts/Alegreya_Sans_500.ttf') format('truetype');
}

@font-face {
  font-family: 'Alegreya Sans';
  font-style: normal;
  font-weight: 700;
  src:
    url('../fonts/Alegreya_Sans_700.woff2') format('woff2'),
    url('../fonts/Alegreya_Sans_700.woff') format('woff'),
    url('../fonts/Alegreya_Sans_700.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Alegreya_Sans_700.svg#AlegreyaSans') format('svg'),
    url('../fonts/Alegreya_Sans_700.ttf') format('truetype');
}