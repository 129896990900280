/* FOOTER */

.footer {
  background-color: $blue;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: space-between;
  // line-height: 1;
  margin-bottom: 24px;
  margin-top: 6px;
  padding-bottom: .6em;
  padding-top: .6em;
  position: relative;
  width: 100%;

  .left {
    flex-grow: 1;
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
  }

  .right {
    flex-grow: 1;
    margin: 0;
    padding-right: 32px;
    text-align: right;
  }
}

/* /FOOTER */
