/* MAIN */

html {
  // -webkit-font-smoothing: antialiased; // off because fonts get too thin on chrome/safari/webkit
  height: 101%;
  overflow-y: scroll;
  // text-rendering: optimizeLegibility;
}

body {
  margin-top: 0;
  // overflow:auto;
}

a { // http://css-tricks.com/removing-the-dotted-outline/
  outline: none;
}

a:link,
a:visited {
  border-bottom: 1px solid transparent;
  color: $white;
  outline: 0;
  text-decoration: none;
  transition: border-bottom-color .4s, color .4s;
}

a:hover,
a:active,
a:focus {
  border-bottom: 1px solid $white-50;
  color: $white-50;
  outline: 0;
  text-decoration: none;
}

a:focus,
a:active {
  border-bottom: 0;
}

a.btn:link,
a.btn:visited {
  border-bottom: 0;
  transition: background-color .4s;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
  border-bottom: 0;
}



img {
  border: 0;
  outline: 0;
}

p {
  margin: 1em 0;
}

/* /MAIN */
