/* COLORS */


$white:   #fff !default;
$black:   #000 !default;
$blue:    #09a4d0 !default;
$orange:  #e0831c !default;
$dorange: #e0611c !default;
$gray:    #8b8d85 !default;
$dgray:   #646660 !default;
$lgray:   #c9ccc0 !default;

$bg-google-map-iframe: #eaeaea;
$bg-debug: rgba(255, 0, 0, .25);

$white-50: rgba(255, 255, 255, .5);

/* /COLORS */
