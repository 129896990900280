/* KONTAKT */

// === Kontakt Mixins ========================================================

@mixin bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin bg-image-parent-mobile {
  box-sizing: border-box;
  height: 0;
  margin-bottom: 6px;
  padding-top: 100%;
  position: relative;
  width: 100%;
}

@mixin bg-image-parent-tablet {
  border-right: 6px solid $white;
  height: auto;
  margin-bottom: 0;
  padding-top: 0;
}

// === Kontakt Responsive ====================================================

// --- MOBILE ----------------------------------------------------------------

.tpl-kontakt {
  .content {
    .block--left {
      .block--img-top {
        @include bg-image-parent-mobile;

        .block--img-top--inner {
          @include bg-image;
        }
      }

      .block--img-bottom {
        @include bg-image-parent-mobile;
        margin-top: 6px;

        .block--img-bottom--inner {
          @include bg-image;
        }
      }
    }

    .block--right {
      margin-bottom: 6px;
    }
  }

} // END: .tpl-kontakt

// --- TABLET ----------------------------------------------------------------

@media (min-width: 768px) {

  .tpl-kontakt {
    .content {
      // .block--left {}
      // .block--right {}
    }
  } // END: .tpl-kontakt

} // END: @media


// --- DESKTOP ---------------------------------------------------------------

@media (min-width: 1024px) {

  .tpl-kontakt {
    .content {
      display: table;
      background-color: rgba(255, 0, 0, 0.25);

      .block--left {
        display: table-cell;
        width: 33%;
        // .block--img-top {}
        // .block--img-bottom {}
        margin-right: 6px solid $white;
      }

      .block--right {
        display: table-cell;
        width: 67%;
        margin-bottom: 0;
        // .block--kontakt {}
      }
    }
  } // END: .tpl-kontakt

} // END: @media


// === Kontakt Basics ========================================================

.tpl-kontakt {


  // *** BLOCK LEFT **********************************************************

  .block--left {
    background-color: $gray;
    border-right: 6px solid $white;
    box-sizing: border-box;

    .block--img-top {
      background-color: $blue;
    }

    .block--img-bottom {
      background-color: $orange;
    }

  }


  // *** BLOCK RIGHT ********************************************************

  .block--right {
    .block--kontakt {
      background-color: $gray;
      font-size: 18px;

      .block--kontakt--inner {
        color: $white;
        padding: 12px 32px;

        h3 {
          color: $lgray;
          font-weight: 700;
          letter-spacing: .15em;
          text-transform: uppercase;
        }
      }
    }
  }

} // END: .tpl-kontakt

/* /KONTAKT */
