/* COOKIE CONSENT */


// === Announcement Basics =========================================================

.pwcmb {
    box-sizing: border-box;
}
.pwcmb * {
    box-sizing: border-box;
}

.pwcmb {
    position: fixed;
    z-index: 601;
    width: 100%;
    left: 0;
    transition: top 0.25s;
}

.js-show.pwcmb--top_overlay,
.js-show.pwcmb--top_push {
    top: 0px;
}

.pwcmb--bottom_overlay {
    bottom: -400px;
}

.js-show.pwcmb--bottom_overlay {
    bottom: 0px;
}

.pwcmb-widget {
    border-top: 6px solid white;
    position: absolute;
    width: 100%;
    // padding: 20px;
    transition: 0.25s;
    background: $dorange;
    font-size: 18px;
    // padding: 0 3.48958%;
    padding: 0 4.75%;
}

.pwcmb--bottom_overlay .pwcmb-widget { bottom: -100vh; }
.pwcmb--bottom_overlay .js-show.pwcmb-widget {
    bottom: 0;
}
.pwcmb--top_overlay .pwcmb-widget { top: -100vh; }
.pwcmb--top_overlay .js-show.pwcmb-widget {
    top: 0;
}

.pwcmb-widget__close {
    visibility: hidden;
    cursor: pointer;
    display: none;
}

.pwcmb-widget__close::before {
    content: '×';
    visibility: visible;
    // display: block;
    display: none;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 30px;
    overflow: hidden;
}

.pwcmb-widget__copy {
    padding-right: 20px;
}

.pwcmb-widget__title {
    font-weight: bold;
}

.pwcmb-widget__row-label { font-weight: bold; }
.pwcmb-widget__row-text { display: inline; }
.pwcmb-widget__row-text:before { content: ' - '; }

.pwcmb-widget__actions {
    white-space: nowrap;
}

.pwcmb-widget button {
    white-space: nowrap;
    background-color: transparent;
    color: white;
    border: 2px solid white;
    padding: 5px 8px;
    min-width: 100px;
    font-size: 18px;
    @include font-printwerk;
}

.js-pwcmb-notice-toggle {
    cursor: pointer;
}

.pwcmb-option-wrapper {
    position: relative;
}


@media screen and (max-width: 767px) {
  .pwcmb-widget__inner {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
  .pwcmb-widget__row-label,
  .pwcmb-widget__text {
    // font-size: 12px;
    font-size: 18px;
  }

  .pwcmb-widget__actions {
    padding-top: 1px;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (min-width: 768px) {
  .pwcmb-widget__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pwcmb-widget__actions {
    margin-left: auto;
  }

}



.pwcmb-widget--message {
  display: none;
}
/* /COOKIE CONSENT */

