//****************************************************************************
// Mixins & Extends
//
//


// Clearfix
// ===========================================================================
// Usage: @include cf();

@mixin cf() {

  &:after {
    clear: both;
    content: " ";   // *1
    display: block; // *2
    line-height: 0;
  }
}


// Text Display under Image (Mobile/Tablet)
// ===========================================================================
// Usage: @include text_under_image();

@mixin text-under-image() {
  color: $black;
  font-size: 15px;
  letter-spacing: .75px;
  line-height: 2;
  margin-top: .75em;
  text-transform: uppercase;

  .name {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .client,
  .colon {
    font-size: 16px;
    font-weight: bold;
  }
}


// Disable selectability for text elements
// ===========================================================================
// Usage: @extend %not_selectable;

// scss-lint:disable VendorPrefix

%not-selectable {
  -webkit-touch-callout: none; // iOS Safari
  -khtml-user-select: none;    // Konqueror
  -moz-user-select: none;      // Firefox
  -ms-user-select: none;       // IE/Edge
  -webkit-user-select: none;   // Chrome/Safari/Opera
  user-select: none;           // non-prefixed version, currently not supported by any browser
}

// scss-lint:enable VendorPrefix
